const getInTouch = document.querySelectorAll("#getInTouch");

getInTouch.forEach((getInTouchButton) => {
  getInTouchButton.addEventListener("click", () => {
    window.lintrk("track", { conversion_id: 9712818 });
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-10975568749/O60tCM2czd4DEO3Gx_Eo",
      event_callback: callback,
    });
    return false;
  });
});
